import { Canvas } from '@react-three/fiber';
import React from "react";
import { OrbitControls, Stage, PerspectiveCamera, Loader} from "@react-three/drei";
import { Suspense } from "react";
import Beer from "./models/Beer";
import './App.css';

function App() {
    const ref = React.useRef()

    return (
        <div className="App">
            <Canvas dpr={[1, 2]} camera={{ fov: 50, position: [50, 0, 25] }} style={{ background: "linear-gradient(90deg, rgba(75,165,74,1) 0%, rgba(74,164,207,1) 47%, rgba(75,165,74,1) 100%)" }}>
                <Suspense fallback={null}>
                    <Stage controls={ref} preset="portrait" intensity={2}    shadows={false} environment="apartment">
                        <group position={[0, 2, 0]}>
                            <Beer />
                        </group>
                    </Stage>
                </Suspense>
                <OrbitControls enableZoom={true} enablePan={false} autoRotate target={[0, 0, 0]} />
            </Canvas>
            <Loader />
        </div>
    );
}

export default App;
